import Axios from "axios";
export const Actions = {
    initApp({commit, dispatch},) {
        dispatch('loadClaims')
    },
    loadClaims(context, payload= 0) {
        let url = '/api/v1/claims'
        if (payload) {
            url += '/' + payload
        }
        Axios.get(url).then((r) => {
            if (r?.data?._status && r?.data?.claims) {
                context.commit('setClaims', r.data.claims)
            }
        })
    },

    setLastTimestamp(context, timestamp) {
        if (!(timestamp && timestamp > 0)) {
            timestamp = 0;
        }
        context.commit('updaterSetLastTimestamp', timestamp);
    },

    loginByCode({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            var url = appConfig.baseAPIURL + '/api/v1/auth-code';

            Axios({url: url, data: data, method: 'POST'})
                .then(resp => {
                    if (resp.data._status && resp.data.token) {
                        let APIToken = resp.data.token;
                        dispatch('updateToken', {APIToken});
                        resolve(resp);
                    } else {
                        reject();
                    }
                })
                .catch(err => {
                    console.log(err);
                    localStorage.removeItem('token');
                    reject(err);
                });
        });
    },

    updateToken({commit}, payload) {
        let APIToken = payload.APIToken;
        localStorage.setItem('token', APIToken);
        Axios.defaults.headers.common['Authorization'] = APIToken;
        commit('setToken', APIToken)
    }

}
