import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

import Axios from "axios";
import { appConfig } from "./config.js";
Vue.use(appConfig);
window.appConfig = appConfig;
Axios.defaults.baseURL = appConfig.baseAPIURL;
Vue.prototype.$http = Axios;
const APIToken = localStorage.getItem("token") ? localStorage.getItem("token")  :  '';
if (APIToken) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] = APIToken;
}

import { registerLicense } from '@syncfusion/ej2-base'
registerLicense('ORg4AjUWIQA/Gnt2UFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Xd0VjWntYdHRXTmFd')

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
