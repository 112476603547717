<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >


    <auth-by-code v-if="isAuthByCode"></auth-by-code>

    <component v-else-if="isLoggedIn" :is="layout">
      <router-view />
    </component>

  </div>
</template>

<script>
import Vue from 'vue'
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import AuthByCode from '../src/views/auth/by-code'
import store from '@/store'
import * as dayjs from "dayjs";
import {mapGetters} from "vuex";
import Axios from "axios";

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')


Vue.filter('formatDate', function (v) {
  if (v) {
    return  dayjs(v).format('DD/MM/YYYY')
  }
  return ''
})

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    AuthByCode
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn'
    }),
    isAuthByCode() {
      return !!(this.$route && this.$route.params && this.$route.params.authCode && this.$route.params.authCode != '');
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.$store.dispatch('initApp')
    }
    if (!this.isUpdate) {
      this.isUpdate = setInterval(() => {
        this.checkUpdate()
      }, 5000)
    }
  },
  methods: {
    checkUpdate() {
      if (this.$store.getters['isLoggedIn']) {
        let timestamp = this.$store.getters['lastTimestamp']
        Axios.get('/api/v1/update/' + timestamp)
            .then(response => {
              if (response.data._status) {
                this.$store.dispatch('setLastTimestamp', response.data._timestamp, { root: true })
                if (response.data.update) {
                  if (response.data.update.claims && response.data.update.claims > 0) {
                    this.$store.dispatch('loadClaims', response.data.update.timestamp, { root: true })
                  }
                }
              }
            })
      }
    }
  },
  data() {
    return {
      isUpdate: null,
    }
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style>
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';
</style>

<style>
.page-breadcrumbs-manual span:hover {
  color: #7367f0;
  cursor: pointer;
}
.page-breadcrumbs-manual span {
  /*font-weight: bold ;*/
}
.page-breadcrumbs-manual span.active:hover {
  cursor: default;
}
.page-breadcrumbs-manual span.active {
  color: #7367f0;
}
</style>
