/*eslint no-unused-vars: "off"*/
export const Getters = {
    claims: state => {
        return _.orderBy(state.claims, ['updatedDate', 'refNbr'], ['desc', 'desc'])
    },
    isLoggedIn: (state) => {
        return (Boolean(state.token && state.token !== '' && state.token !== 'undefined' && state.token !== undefined && state.token.length > 1))
    },
    lastTimestamp: state => {
        if (!state.lastTimestamp) {
            return 0;
        }
        return (state.lastTimestamp - 1);
    },
};
