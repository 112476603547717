/* eslint-disble */
import _ from "lodash";

export const Mutations = {
    setClaims(state, ui) {
        state.claims = _.unionBy(ui, state.claims, 'id')
    },
    setToken(state, ui) {
        state.token = ui
    },
    updaterSetLastTimestamp(state, timestamp) {
        state.lastTimestamp = timestamp;
    },
}
