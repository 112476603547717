import Vue from 'vue'
import Vuex from 'vuex'

import {States} from './states';
import {Getters} from './getters';
import {Mutations} from './mutations';
import {Actions} from './actions';

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  state: States,
  getters: Getters,
  actions: Actions,
  mutations: Mutations,
  strict: process.env.DEV,
})
